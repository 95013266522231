<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-lg-4 col-12">
                                <v-autocomplete v-model="consigneeModel" :items="consigneeLists" item-value="city" item-text="address" :search-input.sync="searchConsignee"
                                cache-items flat hide-no-data hide-details dense clearable label="CONSIGNEE ADDRESS" prepend-icon="mdi-city" ></v-autocomplete>
                            </div> -->
                            <div class="col-lg-4 col-2">
                                <v-select v-model="consigneeModel" :items="consigneeLists" item-value="city" item-text="address" label="CONSIGNEE ADDRESS" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="brandModel" :items="brandLists" item-value="brand_desc" item-text="brand_desc" label="BRAND" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="thicknessModel" :items="thicknessLists" item-value="thick" item-text="thick" label="THICK." clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="widthModel" :items="widthLists" item-value="width" item-text="width" label="WIDTH" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="azModel" :items="azLists" item-value="coat_mass" item-text="coat_mass" label="AZ" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="gradeModel" :items="gradeLists" item-value="grade_id" item-text="grade_id" label="GRADE" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="colorModel" :items="colorLists" item-value="color" item-text="color" label="COLOR" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-8 col-12">
                                <v-btn class="mt-3" color="primary" elevation="4" rounded small @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <td class="text-left w-50">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                    </td>
                                    <td class="text-right w-50">
                                        <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                    </td>
                                </v-toolbar>
                            </template>
                            <template slot="body.append">
                                <tr class="pink--text">
                                    <th class="title">Totals</th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title"></th>
                                    <th class="title text-right">{{ formatNumber(sumField('WGT_ORDER')) }}</th>
                                    <th class="title text-right">{{ formatNumber(sumField('WGT_P3')) }}</th>
                                    <th class="title text-right">{{ formatNumber(sumField('WGT_SHIPPED')) }}</th>
                                    <th class="title text-right">{{ formatNumber(sumField('WGT_OUTSTANDING')) }}</th>
                                </tr>
                            </template>
                            <!-- <template v-slot:[`item.WGT_ORDER`]="{ item }">
                                <td class="text-right">
                                    <span class="badge badge-primary custFont">{{ item.WGT_ORDER }}</span>
                                </td>
                            </template>
                            <template v-slot:[`item.WGT_P3`]="{ item }">
                                <td class="text-right">
                                    <span class="badge badge-info custFont">{{ item.WGT_P3 }}</span>
                                </td>
                            </template>
                            <template v-slot:[`item.WGT_SHIPPED`]="{ item }">
                                <td class="text-right" v-if="item.WGT_SHIPPED == '0'">
                                    <span class="badge badge-danger custFont">{{ item.WGT_SHIPPED }}</span>
                                </td>
                                <td class="text-right" v-else>
                                    <span class="badge badge-warning text-dark custFont">{{ item.WGT_SHIPPED }}</span>
                                </td>
                            </template>
                            <template v-slot:[`item.WGT_OUTSTANDING`]="{ item }">
                                <td class="text-right">
                                    <span class="badge badge-warning text-dark custFont">{{ item.WGT_OUTSTANDING }}</span>
                                </td>
                            </template> -->
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Web',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Sales Report',
                disabled: false,
                href: '#',
                },
                {
                text: 'KKA SC Consignee',
                disabled: true,
                href: '#',
                },
            ],
            consigneeModel: '',
            consigneeLists: [],
            searchConsignee: null,
            brandModel: '',
            brandLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            azModel: '',
            azLists: [],
            gradeModel: '',
            gradeLists: [],
            colorModel: '',
            colorLists: [],
            itemLists: [],
            headers: [
                { text: 'NO. SC', value: 'NO_SC', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CONSIGNEE', value: 'CONSIGNEE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'BRAND', value: 'BRAND', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'THICK.', value: 'THICKNESS', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WIDTH.', value: 'WIDTHNESS', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AZ.', value: 'AZ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GRADE', value: 'GRADE', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COLOR', value: 'COLOR', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'WGT. ORDER(KG)', value: 'WGT_ORDER', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. P3(KG)', value: 'WGT_P3', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. SHIP(KG)', value: 'WGT_SHIPPED', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. OUTS.(KG)', value: 'WGT_OUTSTANDING', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            exportToExcelLists: []
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            $('.cardResult').hide()

            this.$store.dispatch('setOverlay', true)

            axios.get(`${process.env.VUE_APP_URL}/api/sr/KKAConsigneeSC`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.consigneeLists = res.data.consignee
                this.brandLists = res.data.brand
                this.thicknessLists = res.data.thick
                this.widthLists = res.data.width
                this.azLists = res.data.coat_mass
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getData(){

            this.itemLists = []
            this.$store.dispatch('setOverlay', true)
            $('.cardResult').show()
            this.loadingDatatable = true
            this.exportToExcelLists = []
            
            await axios.post(`${process.env.VUE_APP_URL}/api/sr/KKAConsigneeSC/getData`, { 
                    city: this.consigneeModel ? this.consigneeModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    thick: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    az: this.azModel ? this.azModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    color: this.colorModel ? this.colorModel : '',
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false
                this.itemLists = res.data.result
                this.exportToExcelLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getConsignee(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/KKAConsigneeSC/getConsignee`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.consigneeLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "KKA-SC-Consignee-Outstanding-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

        sumField(key) {
            return this.itemLists.reduce((a, b) => a + (b[key] || 0), 0)
        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

    },
    watch: {
        searchConsignee(val) {
            val && val !== this.consigneeModel && this.getConsignee(val)
        }
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

    .custFont {
        font-size: 1em;
    }

</style>